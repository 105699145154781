exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-class-details-page-tsx": () => import("./../../../src/templates/ClassDetailsPage.tsx" /* webpackChunkName: "component---src-templates-class-details-page-tsx" */),
  "component---src-templates-confirm-booking-tsx": () => import("./../../../src/templates/ConfirmBooking.tsx" /* webpackChunkName: "component---src-templates-confirm-booking-tsx" */),
  "component---src-templates-home-page-tsx": () => import("./../../../src/templates/HomePage.tsx" /* webpackChunkName: "component---src-templates-home-page-tsx" */),
  "component---src-templates-member-signup-tsx": () => import("./../../../src/templates/MemberSignup.tsx" /* webpackChunkName: "component---src-templates-member-signup-tsx" */),
  "component---src-templates-my-account-tsx": () => import("./../../../src/templates/MyAccount.tsx" /* webpackChunkName: "component---src-templates-my-account-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/Page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-prospect-book-tsx": () => import("./../../../src/templates/ProspectBook.tsx" /* webpackChunkName: "component---src-templates-prospect-book-tsx" */),
  "component---src-templates-reset-password-page-tsx": () => import("./../../../src/templates/ResetPasswordPage.tsx" /* webpackChunkName: "component---src-templates-reset-password-page-tsx" */),
  "component---src-templates-sign-in-page-tsx": () => import("./../../../src/templates/SignInPage.tsx" /* webpackChunkName: "component---src-templates-sign-in-page-tsx" */),
  "component---src-templates-sign-up-page-tsx": () => import("./../../../src/templates/SignUpPage.tsx" /* webpackChunkName: "component---src-templates-sign-up-page-tsx" */),
  "component---src-templates-studio-details-tsx": () => import("./../../../src/templates/StudioDetails.tsx" /* webpackChunkName: "component---src-templates-studio-details-tsx" */),
  "component---src-templates-studio-staff-signup-tsx": () => import("./../../../src/templates/StudioStaffSignup.tsx" /* webpackChunkName: "component---src-templates-studio-staff-signup-tsx" */)
}

